import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';



const firebaseConfig = {
    apiKey: "AIzaSyCbCfoDThvDuv1jcrYxR40dyI_wJKAU5-g",
    authDomain: "saarang-2025.firebaseapp.com",
    projectId: "saarang-2025",
    storageBucket: "saarang-2025.firebasestorage.app",
    messagingSenderId: "514616872816",
    appId: "1:514616872816:web:9bbf2147a48b9d8a37c9b5",
    measurementId: "G-YWFVJBNXFS"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAmWl2re9q-v3RkkyVQ6rglKomkSOoJ59E",
//   authDomain: "main-portal-2025.firebaseapp.com",
//   projectId: "main-portal-2025",
//   storageBucket: "main-portal-2025.firebasestorage.app",
//   messagingSenderId: "252294757509",
//   appId: "1:252294757509:web:6330ea5c765e1176873dec",
//   measurementId: "G-E00QDBNG2D"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBTLS_2ncbjjAOf2Kv99K0pLPbcnNT7MxA",
//   authDomain: "saarang.org..",
//   projectId: "main-portal-saarang",
//   storageBucket: "main-portal-saarang.firebasestorage.app",
//   messagingSenderId: "124811598946",
//   appId: "1:124811598946:web:b4d568c765d8f69d19853b",
//   measurementId: "G-PRKD84BBNX"
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app; 