// authContext.tsx
import React, { createContext, useState, useContext, useEffect } from "react";
import {
  Auth,
  User as FirebaseUser,
  GoogleAuthProvider,
  signInWithPopup,
  signOut as firebaseSignOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../pages/auth/firebase/firebase";
import { useGoogleSignInMutation, useGetMeQuery } from "../generated/graphql";
import client from "../apolloClient";
interface AuthContextType {
  currentUser: SaarangUser | null;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  state: any;
  loading: boolean;
}
interface SaarangUser {
  user_id: string;
  saarang_id?: string;
  email_primary: string;
  email_secondary?: string | null | undefined;
  username: string;
  mobile_number_primary?: string | null | undefined;
  mobile_number_secondary?: string | null | undefined;
  positions: string;
  DOB?: Date;
  verified?: boolean | null | undefined;
  profile_picture?: string | null | undefined;
  college?: string | null | undefined;
  college_email_id?: string | null | undefined;
  college_location?: string | null | undefined;
  points_scored?: number | null | undefined;
  year_of_study?: string | null | undefined;
  region?: string | null | undefined;
  state?: string | null | undefined;
  isIITM?: string | null | undefined;
  new_user?: boolean | null | undefined;
}
const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<SaarangUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [googleSignInMutation] = useGoogleSignInMutation();
  const { data: meData } = useGetMeQuery();

  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            user: undefined,
            isLoading: false,
          };
      }
    },
    {
      isLoading: true,
      user: null,
    }
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const { data } = await googleSignInMutation({
            variables: {
              uid: firebaseUser.uid,
              email: firebaseUser.email || "",
              displayName: firebaseUser.displayName || "",
              photoUrl: firebaseUser.photoURL || "",
            },
          });

          if (data?.googleSignIn) {
            setCurrentUser(data.googleSignIn);
            dispatch({
              type: "SIGN_IN",
              user: {
                user_id: data.googleSignIn.user_id,
                positions: data.googleSignIn.positions,
                username: data.googleSignIn.username,
              },
            });
          }
        } catch (error) {
          console.error("Error syncing with backend:", error);
        }
      } else {
        setCurrentUser(null);
        dispatch({ type: "SIGN_OUT" });
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);

      // Get the user data from the mutation response
      const { data } = await googleSignInMutation({
        variables: {
          uid: result.user.uid,
          email: result.user.email || "",
          displayName: result.user.displayName || "",
          photoUrl: result.user.photoURL || "",
        },
      });

      // Check the mobile number from the mutation response
      if (data?.googleSignIn) {
        if (
          !data.googleSignIn.mobile_number_primary ||
          data.googleSignIn.mobile_number_primary.length === 0
        ) {
          window.location.href = "/profile-setup";
        } else {
          window.location.href = "/";
        }
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      dispatch({ type: "SIGN_OUT" });
      client.resetStore();
      window.location.reload();
      window.location.href = "/auth";
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  const value = {
    currentUser,
    signInWithGoogle,
    signOut,
    state,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
