import { ApolloClient, InMemoryCache } from "@apollo/client";

const  nodeEnv = process.env.NODE_ENV || "production";

const uri = nodeEnv === "production" ? "https://api.saarang.org/graphql" : "http://localhost:7000/graphql";

const client = new ApolloClient({
  // uri: "http://localhost:7000/graphql",

   uri: uri,

  cache: new InMemoryCache(),
  credentials: "include",
});

export default client;
