import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Accommodation = {
  __typename?: 'Accommodation';
  aadhaar: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  arrival: Scalars['DateTimeISO']['output'];
  college_name: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  departure: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mobile_no: Scalars['String']['output'];
  name: Scalars['String']['output'];
  num_females: Scalars['Float']['output'];
  num_males: Scalars['Float']['output'];
  razorpay_order_id: Scalars['String']['output'];
  razorpay_payment_id?: Maybe<Scalars['String']['output']>;
  refunds?: Maybe<Array<Refund>>;
  student_id: Scalars['String']['output'];
  user: Users;
  verified: Scalars['Boolean']['output'];
};

export type CancelAccommodationInput = {
  id: Scalars['String']['input'];
  num_females: Scalars['Float']['input'];
  num_males: Scalars['Float']['input'];
};

export type Club = {
  __typename?: 'Club';
  ContactName?: Maybe<Scalars['String']['output']>;
  ContactNumber?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  clubName: Scalars['String']['output'];
  clubPassPrice: Scalars['Float']['output'];
  events?: Maybe<Array<Events>>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
};

export type ClubPassRegistration = {
  __typename?: 'ClubPassRegistration';
  club_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date: Scalars['String']['output'];
  registered_event_ids?: Maybe<Array<Scalars['String']['output']>>;
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type ClubPassRegistrationWithDetails = {
  __typename?: 'ClubPassRegistrationWithDetails';
  club: Club;
  registration: ClubPassRegistration;
};

export type ClubPassRegistrationWithDetailsWithUser = {
  __typename?: 'ClubPassRegistrationWithDetailsWithUser';
  club: Club;
  registration: ClubPassRegistration;
  user: Users;
};

export type ComboPass = {
  __typename?: 'ComboPass';
  club_ids: Array<Scalars['String']['output']>;
  current_registrations?: Maybe<Scalars['Float']['output']>;
  description: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  max_registrations?: Maybe<Scalars['Float']['output']>;
  price: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  valid_till?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ComboPassRegistration = {
  __typename?: 'ComboPassRegistration';
  club_pass_registration_ids?: Maybe<Array<Scalars['String']['output']>>;
  combo_pass_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  payment_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type ComboPassRegistrationDetails = {
  __typename?: 'ComboPassRegistrationDetails';
  clubs: Array<Club>;
  comboPass: ComboPass;
  registration: ComboPassRegistration;
  user: Users;
};

export type ComboPassWithClubs = {
  __typename?: 'ComboPassWithClubs';
  clubs: Array<Club>;
  comboPass: ComboPass;
};

export type CreateAccommodationInput = {
  aadhaar: Scalars['String']['input'];
  arrival: Scalars['DateTimeISO']['input'];
  college_name: Scalars['String']['input'];
  departure: Scalars['DateTimeISO']['input'];
  email: Scalars['String']['input'];
  mobile_no: Scalars['String']['input'];
  name: Scalars['String']['input'];
  num_females: Scalars['Float']['input'];
  num_males: Scalars['Float']['input'];
  student_id: Scalars['String']['input'];
};

export type Events = {
  __typename?: 'Events';
  Club: Club;
  description: Array<Scalars['String']['output']>;
  event_date: Scalars['DateTimeISO']['output'];
  event_location?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  registrations_closed?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

export type MilanEvent = {
  __typename?: 'MilanEvent';
  city: Scalars['String']['output'];
  date: Scalars['DateTimeISO']['output'];
  description: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  maxParticipants: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  venue: Scalars['String']['output'];
};

export type MilanRegistration = {
  __typename?: 'MilanRegistration';
  amount?: Maybe<Scalars['Float']['output']>;
  event_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  participants?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type MilanRegistrationDetails = {
  __typename?: 'MilanRegistrationDetails';
  event: MilanEvent;
  registration: MilanRegistration;
  user: Users;
};

export type Mutation = {
  __typename?: 'Mutation';
  approveSubmission: Scalars['Boolean']['output'];
  check_forgot: Scalars['Boolean']['output'];
  check_reg: Users;
  commonRegister: Users;
  createAccommodation: Accommodation;
  createClub: Club;
  createClubPassRegistration: ClubPassRegistration;
  createComboPass: ComboPass;
  createComboPassRegistration: ComboPassRegistration;
  createDirectClubPassRegistration: ClubPassRegistration;
  createDirectComboPassRegistration: ComboPassRegistration;
  createDirectEventRegistration: SingleEventsRegistration;
  createEvents: Events;
  createMilanEvent: MilanEvent;
  createMilanRegistration: MilanRegistration;
  createSingleEventRegistration: SingleEventsRegistration;
  createSubmission: Scalars['Boolean']['output'];
  createTask: Task;
  deleteAccommodation: Scalars['Boolean']['output'];
  deleteClub: Scalars['Boolean']['output'];
  deleteComboPass: Scalars['Boolean']['output'];
  deleteEvents: Scalars['Boolean']['output'];
  deleteMilanEvent: Scalars['Boolean']['output'];
  deleteTask: Scalars['Boolean']['output'];
  getAccommodationById: Accommodation;
  googleSignIn: Users;
  login: Users;
  logout: Scalars['Float']['output'];
  refundAccommodation: Scalars['Boolean']['output'];
  register: Users;
  registerSa: Users;
  register_new: Users;
  sendVerificationEmail: Scalars['Boolean']['output'];
  updateClub: Club;
  updateComboPass: ComboPass;
  updateEvents?: Maybe<Events>;
  updateMilanEvent?: Maybe<MilanEvent>;
  updateProfile: Users;
  updateTask: Task;
  updateUserPosition: Users;
  update_points: Scalars['Boolean']['output'];
  verifyEventsPayment: SingleEventsRegistration;
  verifyPayment: MilanRegistration;
};


export type MutationApproveSubmissionArgs = {
  data: ApproveSubmissionInput;
};


export type MutationCheck_ForgotArgs = {
  email: Scalars['String']['input'];
  pass: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCheck_RegArgs = {
  email_base64: Scalars['String']['input'];
  login_hash: Scalars['String']['input'];
};


export type MutationCommonRegisterArgs = {
  college: Scalars['String']['input'];
  email_primary: Scalars['String']['input'];
  email_secondary?: InputMaybe<Scalars['String']['input']>;
  isIITM: Scalars['String']['input'];
  mobile_number_primary: Scalars['String']['input'];
  mobile_number_secondary?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  portal: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  username: Scalars['String']['input'];
  year_of_study: Scalars['String']['input'];
};


export type MutationCreateAccommodationArgs = {
  data: CreateAccommodationInput;
};


export type MutationCreateClubArgs = {
  ContactName?: InputMaybe<Scalars['String']['input']>;
  ContactNumber?: InputMaybe<Scalars['String']['input']>;
  category: Scalars['String']['input'];
  clubName: Scalars['String']['input'];
  clubPassPrice: Scalars['Float']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateClubPassRegistrationArgs = {
  club_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateComboPassArgs = {
  club_ids: Array<Scalars['String']['input']>;
  description: Array<Scalars['String']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  max_registrations?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  valid_till?: InputMaybe<Scalars['DateTimeISO']['input']>;
};


export type MutationCreateComboPassRegistrationArgs = {
  combo_pass_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateDirectClubPassRegistrationArgs = {
  club_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateDirectComboPassRegistrationArgs = {
  combo_pass_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateDirectEventRegistrationArgs = {
  events_id: Scalars['String']['input'];
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateEventsArgs = {
  clubId: Scalars['String']['input'];
  description: Array<Scalars['String']['input']>;
  event_date: Scalars['DateTimeISO']['input'];
  event_location: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  registrations_closed: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateMilanEventArgs = {
  city: Scalars['String']['input'];
  date: Scalars['DateTimeISO']['input'];
  description: Array<Scalars['String']['input']>;
  maxParticipants: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  venue: Scalars['String']['input'];
};


export type MutationCreateMilanRegistrationArgs = {
  event_id: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateSingleEventRegistrationArgs = {
  events_id: Scalars['String']['input'];
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateSubmissionArgs = {
  data: CreateSubmissionInput;
};


export type MutationCreateTaskArgs = {
  created_at: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  task_desc: Scalars['String']['input'];
  task_name: Scalars['String']['input'];
};


export type MutationDeleteAccommodationArgs = {
  AccommodationID: Scalars['String']['input'];
};


export type MutationDeleteClubArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteComboPassArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteEventsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMilanEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetAccommodationByIdArgs = {
  id: Scalars['String']['input'];
};


export type MutationGoogleSignInArgs = {
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  photoURL: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRefundAccommodationArgs = {
  data: CancelAccommodationInput;
};


export type MutationRegisterArgs = {
  DOB?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email_primary: Scalars['String']['input'];
  email_secondary?: InputMaybe<Scalars['String']['input']>;
  mobile_number_primary?: InputMaybe<Scalars['String']['input']>;
  mobile_number_secondary?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRegisterSaArgs = {
  sa_answer: Scalars['String']['input'];
  sa_answer2: Scalars['String']['input'];
  sa_answer3: Scalars['String']['input'];
  sa_referral?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegister_NewArgs = {
  email_primary: Scalars['String']['input'];
  password: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationSendVerificationEmailArgs = {
  email_primary: Scalars['String']['input'];
};


export type MutationUpdateClubArgs = {
  ContactName?: InputMaybe<Scalars['String']['input']>;
  ContactNumber?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  clubName?: InputMaybe<Scalars['String']['input']>;
  clubPassPrice?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateComboPassArgs = {
  club_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  max_registrations?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  valid_till?: InputMaybe<Scalars['DateTimeISO']['input']>;
};


export type MutationUpdateEventsArgs = {
  clubId: Scalars['String']['input'];
  description: Array<Scalars['String']['input']>;
  event_date: Scalars['DateTimeISO']['input'];
  event_location: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  registrations_closed: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateMilanEventArgs = {
  city: Scalars['String']['input'];
  date: Scalars['DateTimeISO']['input'];
  description: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  maxParticipants: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  venue: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  college: Scalars['String']['input'];
  mobile_number_primary: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  year_of_study: Scalars['String']['input'];
};


export type MutationUpdateTaskArgs = {
  deadline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  task_desc?: InputMaybe<Scalars['String']['input']>;
  task_name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserPositionArgs = {
  positions: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationUpdate_PointsArgs = {
  points_awarded: Scalars['Float']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationVerifyEventsPaymentArgs = {
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type MutationVerifyPaymentArgs = {
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  dummy?: Maybe<Scalars['String']['output']>;
  forgotPassword: Scalars['Boolean']['output'];
  geEventsRegistrationsByEventId: Array<SingleEventsRegistrationDetails>;
  getActiveComboPassesWithAvailability: Array<ComboPassWithClubs>;
  getAllAccommodations: Array<Accommodation>;
  getAllComboPassRegistrations: Array<ComboPassRegistrationDetails>;
  getAllComboPasses: Array<ComboPassWithClubs>;
  getAllEventRegistrations: Array<SingleEventsRegistrationDetails>;
  getAllRefunds: Array<Refund>;
  getAllUsers: Array<Users>;
  getClubPassRegistrations: Array<ClubPassRegistrationWithDetailsWithUser>;
  getClubs: Array<Club>;
  getComboPass: ComboPassWithClubs;
  getEventByClub: Array<Events>;
  getEventBylocation: Array<Events>;
  getEvents: Array<Events>;
  getEventsById?: Maybe<Events>;
  getLeaderboard: Array<Users>;
  getMe: Users;
  getMilanEvent: Array<MilanEvent>;
  getMilanEventByCity: Array<MilanEvent>;
  getMilanEventById?: Maybe<MilanEvent>;
  getMilanRegistrationsByEventId: Array<MilanRegistrationDetails>;
  getMyAccommodations: Array<Accommodation>;
  getMyRefunds: Array<Refund>;
  getSubmissions: Array<Submission>;
  getTasks: Array<Task>;
  getUnsubmittedTasks?: Maybe<Array<Task>>;
  getUserClubPassRegistrations: Array<ClubPassRegistrationWithDetails>;
  getUserComboPassRegistrations: Array<UserComboPassRegistrationDetails>;
  getUserMilanRegistrations: Array<MilanRegistration>;
  getUserSingleEventRegistrations: Array<SingleEventsRegistrationDetails>;
  getUserSubmissions?: Maybe<Array<Submission>>;
  getUsersByPosition: Array<Users>;
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type QueryGeEventsRegistrationsByEventIdArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryGetClubPassRegistrationsArgs = {
  clubId: Scalars['String']['input'];
};


export type QueryGetComboPassArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEventByClubArgs = {
  clubId: Scalars['String']['input'];
};


export type QueryGetEventBylocationArgs = {
  location: Scalars['String']['input'];
};


export type QueryGetEventsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMilanEventByCityArgs = {
  city: Scalars['String']['input'];
};


export type QueryGetMilanEventByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMilanRegistrationsByEventIdArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryGetUserClubPassRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserComboPassRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserMilanRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserSingleEventRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersByPositionArgs = {
  positions: Scalars['String']['input'];
};

export type Refund = {
  __typename?: 'Refund';
  accommodation: Accommodation;
  amountExtra: Scalars['Float']['output'];
  amountRefunded: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  num_females: Scalars['Float']['output'];
  num_males: Scalars['Float']['output'];
  razorpay_refund_id: Scalars['String']['output'];
};

export type SingleEventsRegistration = {
  __typename?: 'SingleEventsRegistration';
  events_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type SingleEventsRegistrationDetails = {
  __typename?: 'SingleEventsRegistrationDetails';
  event: Events;
  registration: SingleEventsRegistration;
  user: Users;
};

export type Submission = {
  __typename?: 'Submission';
  created_at: Scalars['DateTimeISO']['output'];
  files: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  points_awarded: Scalars['Float']['output'];
  task: Task;
  user: Users;
  verification_status: Scalars['Boolean']['output'];
};

export type Task = {
  __typename?: 'Task';
  created_at: Scalars['DateTimeISO']['output'];
  deadline: Scalars['String']['output'];
  id: Scalars['String']['output'];
  points: Scalars['Float']['output'];
  submission?: Maybe<Array<Submission>>;
  task_desc: Scalars['String']['output'];
  task_name: Scalars['String']['output'];
};

export type UserComboPassRegistrationDetails = {
  __typename?: 'UserComboPassRegistrationDetails';
  clubs: Array<Club>;
  comboPass: ComboPass;
  registration: ComboPassRegistration;
};

export type ApproveSubmissionInput = {
  points: Scalars['Float']['input'];
  sub_id: Scalars['String']['input'];
};

export type CreateSubmissionInput = {
  files: Array<Scalars['String']['input']>;
  task_id: Scalars['String']['input'];
};

export type Users = {
  __typename?: 'users';
  DOB?: Maybe<Scalars['DateTimeISO']['output']>;
  accommodation?: Maybe<Accommodation>;
  college?: Maybe<Scalars['String']['output']>;
  college_email_id?: Maybe<Scalars['String']['output']>;
  college_location?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email_primary: Scalars['String']['output'];
  email_secondary?: Maybe<Scalars['String']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  isIITM?: Maybe<Scalars['String']['output']>;
  mobile_number_primary?: Maybe<Scalars['String']['output']>;
  mobile_number_secondary?: Maybe<Scalars['String']['output']>;
  new_user?: Maybe<Scalars['Boolean']['output']>;
  points_scored?: Maybe<Scalars['Float']['output']>;
  positions: Scalars['String']['output'];
  profile_picture?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  sa_answer?: Maybe<Scalars['String']['output']>;
  sa_answer2?: Maybe<Scalars['String']['output']>;
  sa_answer3?: Maybe<Scalars['String']['output']>;
  sa_referral?: Maybe<Scalars['String']['output']>;
  saarang_id: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  submission?: Maybe<Submission>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  username: Scalars['String']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
  year_of_study?: Maybe<Scalars['String']['output']>;
};

export type CommonRegisterMutationVariables = Exact<{
  emailPrimary: Scalars['String']['input'];
  username: Scalars['String']['input'];
  mobileNumberPrimary: Scalars['String']['input'];
  password: Scalars['String']['input'];
  portal: Scalars['String']['input'];
  college: Scalars['String']['input'];
  yearOfStudy: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  isIitm: Scalars['String']['input'];
  positions: Scalars['String']['input'];
}>;


export type CommonRegisterMutation = { __typename?: 'Mutation', commonRegister: { __typename?: 'users', user_id: string, positions: string, username: string } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'users', user_id: string, positions: string, username: string } };

export type RegisterMutationVariables = Exact<{
  emailPrimary: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  positions: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['DateTimeISO']['input']>;
  mobileNumberSecondary?: InputMaybe<Scalars['String']['input']>;
  mobileNumberPrimary?: InputMaybe<Scalars['String']['input']>;
  emailSecondary?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'users', user_id: string } };

export type RegisterSaMutationVariables = Exact<{
  saAnswer: Scalars['String']['input'];
  saAnswer2: Scalars['String']['input'];
  saAnswer3: Scalars['String']['input'];
  saReferral?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterSaMutation = { __typename?: 'Mutation', registerSa: { __typename?: 'users', positions: string, user_id: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: number };

export type Check_ForgotMutationVariables = Exact<{
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
  pass: Scalars['String']['input'];
}>;


export type Check_ForgotMutation = { __typename?: 'Mutation', check_forgot: boolean };

export type UpdateUserPositionMutationVariables = Exact<{
  user_id: Scalars['String']['input'];
  positions: Scalars['String']['input'];
}>;


export type UpdateUserPositionMutation = { __typename?: 'Mutation', updateUserPosition: { __typename?: 'users', user_id: string, positions: string, username: string, email_primary: string } };

export type CreateTaskMutationVariables = Exact<{
  taskName: Scalars['String']['input'];
  taskDesc: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  created_at: Scalars['String']['input'];
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'Task', task_name: string, task_desc: string, points: number, deadline: string, created_at: any } };

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['String']['input'];
  task_desc: Scalars['String']['input'];
  taskName?: InputMaybe<Scalars['String']['input']>;
  deadline?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', task_desc: string } };

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteTaskMutation = { __typename?: 'Mutation', deleteTask: boolean };

export type ApproveSubmissionMutationVariables = Exact<{
  data: ApproveSubmissionInput;
}>;


export type ApproveSubmissionMutation = { __typename?: 'Mutation', approveSubmission: boolean };

export type CreateSubmissionMutationVariables = Exact<{
  data: CreateSubmissionInput;
}>;


export type CreateSubmissionMutation = { __typename?: 'Mutation', createSubmission: boolean };

export type Update_PointsMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  pointsAwarded: Scalars['Float']['input'];
}>;


export type Update_PointsMutation = { __typename?: 'Mutation', update_points: boolean };

export type CreateMilanEventMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  date: Scalars['DateTimeISO']['input'];
  price: Scalars['Float']['input'];
  city: Scalars['String']['input'];
  venue: Scalars['String']['input'];
  maxParticipants: Scalars['Float']['input'];
}>;


export type CreateMilanEventMutation = { __typename?: 'Mutation', createMilanEvent: { __typename?: 'MilanEvent', name: string } };

export type UpdateMilanEventMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  date: Scalars['DateTimeISO']['input'];
  price: Scalars['Float']['input'];
  city: Scalars['String']['input'];
  venue: Scalars['String']['input'];
  maxParticipants: Scalars['Float']['input'];
  updateMilanEventId: Scalars['String']['input'];
}>;


export type UpdateMilanEventMutation = { __typename?: 'Mutation', updateMilanEvent?: { __typename?: 'MilanEvent', id: string } | null };

export type DeleteMilanEventMutationVariables = Exact<{
  deleteMilanEventId: Scalars['String']['input'];
}>;


export type DeleteMilanEventMutation = { __typename?: 'Mutation', deleteMilanEvent: boolean };

export type CreateMilanRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateMilanRegistrationMutation = { __typename?: 'Mutation', createMilanRegistration: { __typename?: 'MilanRegistration', user_id: string, id: string, razorpay_order_id: string, event_id: string, verified: boolean } };

export type CreateAccommodationMutationVariables = Exact<{
  data: CreateAccommodationInput;
}>;


export type CreateAccommodationMutation = { __typename?: 'Mutation', createAccommodation: { __typename?: 'Accommodation', amount: number, razorpay_order_id: string } };

export type RefundAccommodationMutationVariables = Exact<{
  data: CancelAccommodationInput;
}>;


export type RefundAccommodationMutation = { __typename?: 'Mutation', refundAccommodation: boolean };

export type GoogleSignInMutationVariables = Exact<{
  uid: Scalars['String']['input'];
  email: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  photoUrl: Scalars['String']['input'];
}>;


export type GoogleSignInMutation = { __typename?: 'Mutation', googleSignIn: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, points_scored?: number | null, year_of_study?: string | null, region?: string | null, state?: string | null, created_at?: any | null, updated_at?: any | null, isIITM?: string | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type UpdateProfileMutationVariables = Exact<{
  mobileNumberPrimary: Scalars['String']['input'];
  college: Scalars['String']['input'];
  yearOfStudy: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, points_scored?: number | null, year_of_study?: string | null, region?: string | null, state?: string | null, created_at?: any | null, updated_at?: any | null, isIITM?: string | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe: { __typename?: 'users', user_id: string, saarang_id: string, positions: string, username: string, sa_answer?: string | null, sa_referral?: string | null, points_scored?: number | null, email_primary: string, mobile_number_primary?: string | null, college?: string | null } };

export type GetUsersByPositionQueryVariables = Exact<{
  positions: Scalars['String']['input'];
}>;


export type GetUsersByPositionQuery = { __typename?: 'Query', getUsersByPosition: Array<{ __typename?: 'users', username: string, email_primary: string, mobile_number_primary?: string | null, isIITM?: string | null, college?: string | null, points_scored?: number | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, state?: string | null, saarang_id: string, year_of_study?: string | null, positions: string, user_id: string, region?: string | null }> };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', getAllUsers: Array<{ __typename?: 'users', email_primary: string, isIITM?: string | null, mobile_number_primary?: string | null, points_scored?: number | null, positions: string, profile_picture?: string | null, region?: string | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, saarang_id: string, username: string, year_of_study?: string | null, user_id: string, college?: string | null, state?: string | null }> };

export type GetTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTasksQuery = { __typename?: 'Query', getTasks: Array<{ __typename?: 'Task', id: string, task_desc: string, task_name: string, created_at: any, deadline: string }> };

export type GetSubmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubmissionsQuery = { __typename?: 'Query', getSubmissions: Array<{ __typename?: 'Submission', id: string, files: Array<string>, verification_status: boolean, points_awarded: number, created_at: any, task: { __typename?: 'Task', id: string, task_name: string, task_desc: string }, user: { __typename?: 'users', user_id: string, username: string, email_primary: string } }> };

export type GetUnsubmittedTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnsubmittedTasksQuery = { __typename?: 'Query', getUnsubmittedTasks?: Array<{ __typename?: 'Task', id: string, task_name: string, task_desc: string, created_at: any, deadline: string }> | null };

export type GetUserSubmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSubmissionsQuery = { __typename?: 'Query', getUserSubmissions?: Array<{ __typename?: 'Submission', points_awarded: number, created_at: any, verification_status: boolean, task: { __typename?: 'Task', task_name: string, task_desc: string } }> | null };

export type GetLeaderboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLeaderboardQuery = { __typename?: 'Query', getLeaderboard: Array<{ __typename?: 'users', points_scored?: number | null, username: string }> };

export type ForgotPasswordQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordQuery = { __typename?: 'Query', forgotPassword: boolean };

export type GetMilanEventByCityQueryVariables = Exact<{
  city: Scalars['String']['input'];
}>;


export type GetMilanEventByCityQuery = { __typename?: 'Query', getMilanEventByCity: Array<{ __typename?: 'MilanEvent', id: string, name: string, description: Array<string>, date: any, price: number, city: string, venue: string, maxParticipants: number }> };

export type GetMilanEventQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMilanEventQuery = { __typename?: 'Query', getMilanEvent: Array<{ __typename?: 'MilanEvent', city: string, date: any, description: Array<string>, id: string, maxParticipants: number, name: string, price: number, venue: string }> };

export type GetUserMilanRegistrationsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserMilanRegistrationsQuery = { __typename?: 'Query', getUserMilanRegistrations: Array<{ __typename?: 'MilanRegistration', event_id: string, id: string, participants?: number | null, razorpay_order_id: string, user_id: string, verified: boolean, reg_date?: any | null, link?: string | null }> };

export type GetMilanEventByIdQueryVariables = Exact<{
  getMilanEventByIdId: Scalars['String']['input'];
}>;


export type GetMilanEventByIdQuery = { __typename?: 'Query', getMilanEventById?: { __typename?: 'MilanEvent', city: string, date: any, description: Array<string>, id: string, maxParticipants: number, name: string, price: number, venue: string } | null };

export type GetMilanRegistrationsByEventIdQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type GetMilanRegistrationsByEventIdQuery = { __typename?: 'Query', getMilanRegistrationsByEventId: Array<{ __typename?: 'MilanRegistrationDetails', event: { __typename?: 'MilanEvent', city: string, date: any, description: Array<string>, id: string, maxParticipants: number, name: string, price: number, venue: string }, registration: { __typename?: 'MilanRegistration', event_id: string, id: string, razorpay_order_id: string, user_id: string, verified: boolean, link?: string | null }, user: { __typename?: 'users', user_id: string, username: string, verified?: boolean | null, year_of_study?: string | null, email_primary: string, mobile_number_primary?: string | null } }> };

export type GetMyAccommodationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAccommodationsQuery = { __typename?: 'Query', getMyAccommodations: Array<{ __typename?: 'Accommodation', id: string, name: string, email: string, mobile_no: string, num_males: number, num_females: number, arrival: any, departure: any, college_name: string, refunds?: Array<{ __typename?: 'Refund', amountExtra: number, amountRefunded: number, num_females: number, num_males: number }> | null }> };

export type GetAllAccommodationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAccommodationsQuery = { __typename?: 'Query', getAllAccommodations: Array<{ __typename?: 'Accommodation', id: string, name: string, email: string, num_females: number, num_males: number, arrival: any, departure: any, college_name: string, aadhaar: string, student_id: string, amount: number, mobile_no: string }> };

export type GetAllRefundsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRefundsQuery = { __typename?: 'Query', getAllRefunds: Array<{ __typename?: 'Refund', id: string, amountExtra: number, amountRefunded: number, num_females: number, num_males: number, accommodation: { __typename?: 'Accommodation', email: string, mobile_no: string, arrival: any, departure: any, name: string, amount: number } }> };

export type GetMyRefundsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyRefundsQuery = { __typename?: 'Query', getMyRefunds: Array<{ __typename?: 'Refund', id: string, num_males: number, num_females: number, amountRefunded: number, amountExtra: number, accommodation: { __typename?: 'Accommodation', email: string, mobile_no: string, arrival: any, departure: any } }> };


export const CommonRegisterDocument = gql`
    mutation CommonRegister($emailPrimary: String!, $username: String!, $mobileNumberPrimary: String!, $password: String!, $portal: String!, $college: String!, $yearOfStudy: String!, $region: String!, $state: String!, $isIitm: String!, $positions: String!) {
  commonRegister(
    email_primary: $emailPrimary
    username: $username
    mobile_number_primary: $mobileNumberPrimary
    password: $password
    portal: $portal
    college: $college
    year_of_study: $yearOfStudy
    region: $region
    state: $state
    isIITM: $isIitm
    positions: $positions
  ) {
    user_id
    positions
    username
  }
}
    `;
export type CommonRegisterMutationFn = Apollo.MutationFunction<CommonRegisterMutation, CommonRegisterMutationVariables>;

/**
 * __useCommonRegisterMutation__
 *
 * To run a mutation, you first call `useCommonRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommonRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commonRegisterMutation, { data, loading, error }] = useCommonRegisterMutation({
 *   variables: {
 *      emailPrimary: // value for 'emailPrimary'
 *      username: // value for 'username'
 *      mobileNumberPrimary: // value for 'mobileNumberPrimary'
 *      password: // value for 'password'
 *      portal: // value for 'portal'
 *      college: // value for 'college'
 *      yearOfStudy: // value for 'yearOfStudy'
 *      region: // value for 'region'
 *      state: // value for 'state'
 *      isIitm: // value for 'isIitm'
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useCommonRegisterMutation(baseOptions?: Apollo.MutationHookOptions<CommonRegisterMutation, CommonRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommonRegisterMutation, CommonRegisterMutationVariables>(CommonRegisterDocument, options);
      }
export type CommonRegisterMutationHookResult = ReturnType<typeof useCommonRegisterMutation>;
export type CommonRegisterMutationResult = Apollo.MutationResult<CommonRegisterMutation>;
export type CommonRegisterMutationOptions = Apollo.BaseMutationOptions<CommonRegisterMutation, CommonRegisterMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user_id
    positions
    username
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($emailPrimary: String!, $username: String!, $password: String!, $positions: String!, $dob: DateTimeISO, $mobileNumberSecondary: String, $mobileNumberPrimary: String, $emailSecondary: String) {
  register(
    email_primary: $emailPrimary
    username: $username
    password: $password
    positions: $positions
    DOB: $dob
    mobile_number_secondary: $mobileNumberSecondary
    mobile_number_primary: $mobileNumberPrimary
    email_secondary: $emailSecondary
  ) {
    user_id
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      emailPrimary: // value for 'emailPrimary'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      positions: // value for 'positions'
 *      dob: // value for 'dob'
 *      mobileNumberSecondary: // value for 'mobileNumberSecondary'
 *      mobileNumberPrimary: // value for 'mobileNumberPrimary'
 *      emailSecondary: // value for 'emailSecondary'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RegisterSaDocument = gql`
    mutation RegisterSa($saAnswer: String!, $saAnswer2: String!, $saAnswer3: String!, $saReferral: String) {
  registerSa(
    sa_answer: $saAnswer
    sa_answer2: $saAnswer2
    sa_answer3: $saAnswer3
    sa_referral: $saReferral
  ) {
    positions
    user_id
  }
}
    `;
export type RegisterSaMutationFn = Apollo.MutationFunction<RegisterSaMutation, RegisterSaMutationVariables>;

/**
 * __useRegisterSaMutation__
 *
 * To run a mutation, you first call `useRegisterSaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSaMutation, { data, loading, error }] = useRegisterSaMutation({
 *   variables: {
 *      saAnswer: // value for 'saAnswer'
 *      saAnswer2: // value for 'saAnswer2'
 *      saAnswer3: // value for 'saAnswer3'
 *      saReferral: // value for 'saReferral'
 *   },
 * });
 */
export function useRegisterSaMutation(baseOptions?: Apollo.MutationHookOptions<RegisterSaMutation, RegisterSaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterSaMutation, RegisterSaMutationVariables>(RegisterSaDocument, options);
      }
export type RegisterSaMutationHookResult = ReturnType<typeof useRegisterSaMutation>;
export type RegisterSaMutationResult = Apollo.MutationResult<RegisterSaMutation>;
export type RegisterSaMutationOptions = Apollo.BaseMutationOptions<RegisterSaMutation, RegisterSaMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const Check_ForgotDocument = gql`
    mutation Check_forgot($email: String!, $token: String!, $pass: String!) {
  check_forgot(email: $email, token: $token, pass: $pass)
}
    `;
export type Check_ForgotMutationFn = Apollo.MutationFunction<Check_ForgotMutation, Check_ForgotMutationVariables>;

/**
 * __useCheck_ForgotMutation__
 *
 * To run a mutation, you first call `useCheck_ForgotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheck_ForgotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkForgotMutation, { data, loading, error }] = useCheck_ForgotMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useCheck_ForgotMutation(baseOptions?: Apollo.MutationHookOptions<Check_ForgotMutation, Check_ForgotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Check_ForgotMutation, Check_ForgotMutationVariables>(Check_ForgotDocument, options);
      }
export type Check_ForgotMutationHookResult = ReturnType<typeof useCheck_ForgotMutation>;
export type Check_ForgotMutationResult = Apollo.MutationResult<Check_ForgotMutation>;
export type Check_ForgotMutationOptions = Apollo.BaseMutationOptions<Check_ForgotMutation, Check_ForgotMutationVariables>;
export const UpdateUserPositionDocument = gql`
    mutation UpdateUserPosition($user_id: String!, $positions: String!) {
  updateUserPosition(user_id: $user_id, positions: $positions) {
    user_id
    positions
    username
    email_primary
  }
}
    `;
export type UpdateUserPositionMutationFn = Apollo.MutationFunction<UpdateUserPositionMutation, UpdateUserPositionMutationVariables>;

/**
 * __useUpdateUserPositionMutation__
 *
 * To run a mutation, you first call `useUpdateUserPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPositionMutation, { data, loading, error }] = useUpdateUserPositionMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useUpdateUserPositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPositionMutation, UpdateUserPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPositionMutation, UpdateUserPositionMutationVariables>(UpdateUserPositionDocument, options);
      }
export type UpdateUserPositionMutationHookResult = ReturnType<typeof useUpdateUserPositionMutation>;
export type UpdateUserPositionMutationResult = Apollo.MutationResult<UpdateUserPositionMutation>;
export type UpdateUserPositionMutationOptions = Apollo.BaseMutationOptions<UpdateUserPositionMutation, UpdateUserPositionMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($taskName: String!, $taskDesc: String!, $deadline: String!, $points: Float!, $created_at: String!) {
  createTask(
    task_name: $taskName
    task_desc: $taskDesc
    deadline: $deadline
    points: $points
    created_at: $created_at
  ) {
    task_name
    task_desc
    points
    deadline
    created_at
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      taskName: // value for 'taskName'
 *      taskDesc: // value for 'taskDesc'
 *      deadline: // value for 'deadline'
 *      points: // value for 'points'
 *      created_at: // value for 'created_at'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: String!, $task_desc: String!, $taskName: String, $deadline: String) {
  updateTask(
    id: $id
    task_desc: $task_desc
    task_name: $taskName
    deadline: $deadline
  ) {
    task_desc
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      task_desc: // value for 'task_desc'
 *      taskName: // value for 'taskName'
 *      deadline: // value for 'deadline'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($id: String!) {
  deleteTask(id: $id)
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const ApproveSubmissionDocument = gql`
    mutation ApproveSubmission($data: approveSubmissionInput!) {
  approveSubmission(data: $data)
}
    `;
export type ApproveSubmissionMutationFn = Apollo.MutationFunction<ApproveSubmissionMutation, ApproveSubmissionMutationVariables>;

/**
 * __useApproveSubmissionMutation__
 *
 * To run a mutation, you first call `useApproveSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubmissionMutation, { data, loading, error }] = useApproveSubmissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSubmissionMutation, ApproveSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveSubmissionMutation, ApproveSubmissionMutationVariables>(ApproveSubmissionDocument, options);
      }
export type ApproveSubmissionMutationHookResult = ReturnType<typeof useApproveSubmissionMutation>;
export type ApproveSubmissionMutationResult = Apollo.MutationResult<ApproveSubmissionMutation>;
export type ApproveSubmissionMutationOptions = Apollo.BaseMutationOptions<ApproveSubmissionMutation, ApproveSubmissionMutationVariables>;
export const CreateSubmissionDocument = gql`
    mutation CreateSubmission($data: createSubmissionInput!) {
  createSubmission(data: $data)
}
    `;
export type CreateSubmissionMutationFn = Apollo.MutationFunction<CreateSubmissionMutation, CreateSubmissionMutationVariables>;

/**
 * __useCreateSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionMutation, { data, loading, error }] = useCreateSubmissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubmissionMutation, CreateSubmissionMutationVariables>(CreateSubmissionDocument, options);
      }
export type CreateSubmissionMutationHookResult = ReturnType<typeof useCreateSubmissionMutation>;
export type CreateSubmissionMutationResult = Apollo.MutationResult<CreateSubmissionMutation>;
export type CreateSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>;
export const Update_PointsDocument = gql`
    mutation Update_points($userId: String!, $pointsAwarded: Float!) {
  update_points(user_id: $userId, points_awarded: $pointsAwarded)
}
    `;
export type Update_PointsMutationFn = Apollo.MutationFunction<Update_PointsMutation, Update_PointsMutationVariables>;

/**
 * __useUpdate_PointsMutation__
 *
 * To run a mutation, you first call `useUpdate_PointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_PointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePointsMutation, { data, loading, error }] = useUpdate_PointsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      pointsAwarded: // value for 'pointsAwarded'
 *   },
 * });
 */
export function useUpdate_PointsMutation(baseOptions?: Apollo.MutationHookOptions<Update_PointsMutation, Update_PointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_PointsMutation, Update_PointsMutationVariables>(Update_PointsDocument, options);
      }
export type Update_PointsMutationHookResult = ReturnType<typeof useUpdate_PointsMutation>;
export type Update_PointsMutationResult = Apollo.MutationResult<Update_PointsMutation>;
export type Update_PointsMutationOptions = Apollo.BaseMutationOptions<Update_PointsMutation, Update_PointsMutationVariables>;
export const CreateMilanEventDocument = gql`
    mutation CreateMilanEvent($name: String!, $description: [String!]!, $date: DateTimeISO!, $price: Float!, $city: String!, $venue: String!, $maxParticipants: Float!) {
  createMilanEvent(
    name: $name
    description: $description
    date: $date
    price: $price
    city: $city
    venue: $venue
    maxParticipants: $maxParticipants
  ) {
    name
  }
}
    `;
export type CreateMilanEventMutationFn = Apollo.MutationFunction<CreateMilanEventMutation, CreateMilanEventMutationVariables>;

/**
 * __useCreateMilanEventMutation__
 *
 * To run a mutation, you first call `useCreateMilanEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilanEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilanEventMutation, { data, loading, error }] = useCreateMilanEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      date: // value for 'date'
 *      price: // value for 'price'
 *      city: // value for 'city'
 *      venue: // value for 'venue'
 *      maxParticipants: // value for 'maxParticipants'
 *   },
 * });
 */
export function useCreateMilanEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilanEventMutation, CreateMilanEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilanEventMutation, CreateMilanEventMutationVariables>(CreateMilanEventDocument, options);
      }
export type CreateMilanEventMutationHookResult = ReturnType<typeof useCreateMilanEventMutation>;
export type CreateMilanEventMutationResult = Apollo.MutationResult<CreateMilanEventMutation>;
export type CreateMilanEventMutationOptions = Apollo.BaseMutationOptions<CreateMilanEventMutation, CreateMilanEventMutationVariables>;
export const UpdateMilanEventDocument = gql`
    mutation UpdateMilanEvent($name: String!, $description: [String!]!, $date: DateTimeISO!, $price: Float!, $city: String!, $venue: String!, $maxParticipants: Float!, $updateMilanEventId: String!) {
  updateMilanEvent(
    name: $name
    description: $description
    date: $date
    price: $price
    city: $city
    venue: $venue
    maxParticipants: $maxParticipants
    id: $updateMilanEventId
  ) {
    id
  }
}
    `;
export type UpdateMilanEventMutationFn = Apollo.MutationFunction<UpdateMilanEventMutation, UpdateMilanEventMutationVariables>;

/**
 * __useUpdateMilanEventMutation__
 *
 * To run a mutation, you first call `useUpdateMilanEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilanEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilanEventMutation, { data, loading, error }] = useUpdateMilanEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      date: // value for 'date'
 *      price: // value for 'price'
 *      city: // value for 'city'
 *      venue: // value for 'venue'
 *      maxParticipants: // value for 'maxParticipants'
 *      updateMilanEventId: // value for 'updateMilanEventId'
 *   },
 * });
 */
export function useUpdateMilanEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMilanEventMutation, UpdateMilanEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMilanEventMutation, UpdateMilanEventMutationVariables>(UpdateMilanEventDocument, options);
      }
export type UpdateMilanEventMutationHookResult = ReturnType<typeof useUpdateMilanEventMutation>;
export type UpdateMilanEventMutationResult = Apollo.MutationResult<UpdateMilanEventMutation>;
export type UpdateMilanEventMutationOptions = Apollo.BaseMutationOptions<UpdateMilanEventMutation, UpdateMilanEventMutationVariables>;
export const DeleteMilanEventDocument = gql`
    mutation DeleteMilanEvent($deleteMilanEventId: String!) {
  deleteMilanEvent(id: $deleteMilanEventId)
}
    `;
export type DeleteMilanEventMutationFn = Apollo.MutationFunction<DeleteMilanEventMutation, DeleteMilanEventMutationVariables>;

/**
 * __useDeleteMilanEventMutation__
 *
 * To run a mutation, you first call `useDeleteMilanEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMilanEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMilanEventMutation, { data, loading, error }] = useDeleteMilanEventMutation({
 *   variables: {
 *      deleteMilanEventId: // value for 'deleteMilanEventId'
 *   },
 * });
 */
export function useDeleteMilanEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMilanEventMutation, DeleteMilanEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMilanEventMutation, DeleteMilanEventMutationVariables>(DeleteMilanEventDocument, options);
      }
export type DeleteMilanEventMutationHookResult = ReturnType<typeof useDeleteMilanEventMutation>;
export type DeleteMilanEventMutationResult = Apollo.MutationResult<DeleteMilanEventMutation>;
export type DeleteMilanEventMutationOptions = Apollo.BaseMutationOptions<DeleteMilanEventMutation, DeleteMilanEventMutationVariables>;
export const CreateMilanRegistrationDocument = gql`
    mutation CreateMilanRegistration($userId: String!, $eventId: String!, $link: String) {
  createMilanRegistration(user_id: $userId, event_id: $eventId, link: $link) {
    user_id
    id
    razorpay_order_id
    event_id
    verified
  }
}
    `;
export type CreateMilanRegistrationMutationFn = Apollo.MutationFunction<CreateMilanRegistrationMutation, CreateMilanRegistrationMutationVariables>;

/**
 * __useCreateMilanRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateMilanRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilanRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilanRegistrationMutation, { data, loading, error }] = useCreateMilanRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      eventId: // value for 'eventId'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateMilanRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilanRegistrationMutation, CreateMilanRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilanRegistrationMutation, CreateMilanRegistrationMutationVariables>(CreateMilanRegistrationDocument, options);
      }
export type CreateMilanRegistrationMutationHookResult = ReturnType<typeof useCreateMilanRegistrationMutation>;
export type CreateMilanRegistrationMutationResult = Apollo.MutationResult<CreateMilanRegistrationMutation>;
export type CreateMilanRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateMilanRegistrationMutation, CreateMilanRegistrationMutationVariables>;
export const CreateAccommodationDocument = gql`
    mutation CreateAccommodation($data: CreateAccommodationInput!) {
  createAccommodation(data: $data) {
    amount
    razorpay_order_id
  }
}
    `;
export type CreateAccommodationMutationFn = Apollo.MutationFunction<CreateAccommodationMutation, CreateAccommodationMutationVariables>;

/**
 * __useCreateAccommodationMutation__
 *
 * To run a mutation, you first call `useCreateAccommodationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccommodationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccommodationMutation, { data, loading, error }] = useCreateAccommodationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccommodationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccommodationMutation, CreateAccommodationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccommodationMutation, CreateAccommodationMutationVariables>(CreateAccommodationDocument, options);
      }
export type CreateAccommodationMutationHookResult = ReturnType<typeof useCreateAccommodationMutation>;
export type CreateAccommodationMutationResult = Apollo.MutationResult<CreateAccommodationMutation>;
export type CreateAccommodationMutationOptions = Apollo.BaseMutationOptions<CreateAccommodationMutation, CreateAccommodationMutationVariables>;
export const RefundAccommodationDocument = gql`
    mutation RefundAccommodation($data: CancelAccommodationInput!) {
  refundAccommodation(data: $data)
}
    `;
export type RefundAccommodationMutationFn = Apollo.MutationFunction<RefundAccommodationMutation, RefundAccommodationMutationVariables>;

/**
 * __useRefundAccommodationMutation__
 *
 * To run a mutation, you first call `useRefundAccommodationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundAccommodationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundAccommodationMutation, { data, loading, error }] = useRefundAccommodationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRefundAccommodationMutation(baseOptions?: Apollo.MutationHookOptions<RefundAccommodationMutation, RefundAccommodationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundAccommodationMutation, RefundAccommodationMutationVariables>(RefundAccommodationDocument, options);
      }
export type RefundAccommodationMutationHookResult = ReturnType<typeof useRefundAccommodationMutation>;
export type RefundAccommodationMutationResult = Apollo.MutationResult<RefundAccommodationMutation>;
export type RefundAccommodationMutationOptions = Apollo.BaseMutationOptions<RefundAccommodationMutation, RefundAccommodationMutationVariables>;
export const GoogleSignInDocument = gql`
    mutation GoogleSignIn($uid: String!, $email: String!, $displayName: String!, $photoUrl: String!) {
  googleSignIn(
    uid: $uid
    email: $email
    displayName: $displayName
    photoURL: $photoUrl
  ) {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    points_scored
    year_of_study
    region
    state
    created_at
    updated_at
    isIITM
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    google_id
    new_user
  }
}
    `;
export type GoogleSignInMutationFn = Apollo.MutationFunction<GoogleSignInMutation, GoogleSignInMutationVariables>;

/**
 * __useGoogleSignInMutation__
 *
 * To run a mutation, you first call `useGoogleSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignInMutation, { data, loading, error }] = useGoogleSignInMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *      displayName: // value for 'displayName'
 *      photoUrl: // value for 'photoUrl'
 *   },
 * });
 */
export function useGoogleSignInMutation(baseOptions?: Apollo.MutationHookOptions<GoogleSignInMutation, GoogleSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleSignInMutation, GoogleSignInMutationVariables>(GoogleSignInDocument, options);
      }
export type GoogleSignInMutationHookResult = ReturnType<typeof useGoogleSignInMutation>;
export type GoogleSignInMutationResult = Apollo.MutationResult<GoogleSignInMutation>;
export type GoogleSignInMutationOptions = Apollo.BaseMutationOptions<GoogleSignInMutation, GoogleSignInMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($mobileNumberPrimary: String!, $college: String!, $yearOfStudy: String!, $region: String!, $state: String!) {
  updateProfile(
    mobile_number_primary: $mobileNumberPrimary
    college: $college
    year_of_study: $yearOfStudy
    region: $region
    state: $state
  ) {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    points_scored
    year_of_study
    region
    state
    created_at
    updated_at
    isIITM
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    google_id
    new_user
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      mobileNumberPrimary: // value for 'mobileNumberPrimary'
 *      college: // value for 'college'
 *      yearOfStudy: // value for 'yearOfStudy'
 *      region: // value for 'region'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  getMe {
    user_id
    saarang_id
    positions
    username
    sa_answer
    sa_referral
    points_scored
    email_primary
    mobile_number_primary
    college
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetUsersByPositionDocument = gql`
    query GetUsersByPosition($positions: String!) {
  getUsersByPosition(positions: $positions) {
    username
    email_primary
    mobile_number_primary
    isIITM
    college
    points_scored
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    state
    saarang_id
    year_of_study
    positions
    user_id
    region
  }
}
    `;

/**
 * __useGetUsersByPositionQuery__
 *
 * To run a query within a React component, call `useGetUsersByPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByPositionQuery({
 *   variables: {
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useGetUsersByPositionQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByPositionQuery, GetUsersByPositionQueryVariables> & ({ variables: GetUsersByPositionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByPositionQuery, GetUsersByPositionQueryVariables>(GetUsersByPositionDocument, options);
      }
export function useGetUsersByPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByPositionQuery, GetUsersByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByPositionQuery, GetUsersByPositionQueryVariables>(GetUsersByPositionDocument, options);
        }
export function useGetUsersByPositionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersByPositionQuery, GetUsersByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByPositionQuery, GetUsersByPositionQueryVariables>(GetUsersByPositionDocument, options);
        }
export type GetUsersByPositionQueryHookResult = ReturnType<typeof useGetUsersByPositionQuery>;
export type GetUsersByPositionLazyQueryHookResult = ReturnType<typeof useGetUsersByPositionLazyQuery>;
export type GetUsersByPositionSuspenseQueryHookResult = ReturnType<typeof useGetUsersByPositionSuspenseQuery>;
export type GetUsersByPositionQueryResult = Apollo.QueryResult<GetUsersByPositionQuery, GetUsersByPositionQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  getAllUsers {
    email_primary
    isIITM
    mobile_number_primary
    points_scored
    positions
    profile_picture
    region
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    saarang_id
    username
    year_of_study
    user_id
    college
    state
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export function useGetAllUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersSuspenseQueryHookResult = ReturnType<typeof useGetAllUsersSuspenseQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetTasksDocument = gql`
    query GetTasks {
  getTasks {
    id
    task_desc
    task_name
    created_at
    deadline
  }
}
    `;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export function useGetTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksSuspenseQueryHookResult = ReturnType<typeof useGetTasksSuspenseQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const GetSubmissionsDocument = gql`
    query GetSubmissions {
  getSubmissions {
    id
    files
    verification_status
    points_awarded
    created_at
    task {
      id
      task_name
      task_desc
    }
    user {
      user_id
      username
      email_primary
    }
  }
}
    `;

/**
 * __useGetSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, options);
      }
export function useGetSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, options);
        }
export function useGetSubmissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, options);
        }
export type GetSubmissionsQueryHookResult = ReturnType<typeof useGetSubmissionsQuery>;
export type GetSubmissionsLazyQueryHookResult = ReturnType<typeof useGetSubmissionsLazyQuery>;
export type GetSubmissionsSuspenseQueryHookResult = ReturnType<typeof useGetSubmissionsSuspenseQuery>;
export type GetSubmissionsQueryResult = Apollo.QueryResult<GetSubmissionsQuery, GetSubmissionsQueryVariables>;
export const GetUnsubmittedTasksDocument = gql`
    query GetUnsubmittedTasks {
  getUnsubmittedTasks {
    id
    task_name
    task_desc
    created_at
    deadline
  }
}
    `;

/**
 * __useGetUnsubmittedTasksQuery__
 *
 * To run a query within a React component, call `useGetUnsubmittedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsubmittedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsubmittedTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnsubmittedTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>(GetUnsubmittedTasksDocument, options);
      }
export function useGetUnsubmittedTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>(GetUnsubmittedTasksDocument, options);
        }
export function useGetUnsubmittedTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>(GetUnsubmittedTasksDocument, options);
        }
export type GetUnsubmittedTasksQueryHookResult = ReturnType<typeof useGetUnsubmittedTasksQuery>;
export type GetUnsubmittedTasksLazyQueryHookResult = ReturnType<typeof useGetUnsubmittedTasksLazyQuery>;
export type GetUnsubmittedTasksSuspenseQueryHookResult = ReturnType<typeof useGetUnsubmittedTasksSuspenseQuery>;
export type GetUnsubmittedTasksQueryResult = Apollo.QueryResult<GetUnsubmittedTasksQuery, GetUnsubmittedTasksQueryVariables>;
export const GetUserSubmissionsDocument = gql`
    query GetUserSubmissions {
  getUserSubmissions {
    task {
      task_name
      task_desc
    }
    points_awarded
    created_at
    verification_status
  }
}
    `;

/**
 * __useGetUserSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetUserSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>(GetUserSubmissionsDocument, options);
      }
export function useGetUserSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>(GetUserSubmissionsDocument, options);
        }
export function useGetUserSubmissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>(GetUserSubmissionsDocument, options);
        }
export type GetUserSubmissionsQueryHookResult = ReturnType<typeof useGetUserSubmissionsQuery>;
export type GetUserSubmissionsLazyQueryHookResult = ReturnType<typeof useGetUserSubmissionsLazyQuery>;
export type GetUserSubmissionsSuspenseQueryHookResult = ReturnType<typeof useGetUserSubmissionsSuspenseQuery>;
export type GetUserSubmissionsQueryResult = Apollo.QueryResult<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>;
export const GetLeaderboardDocument = gql`
    query GetLeaderboard {
  getLeaderboard {
    points_scored
    username
  }
}
    `;

/**
 * __useGetLeaderboardQuery__
 *
 * To run a query within a React component, call `useGetLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaderboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
      }
export function useGetLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
        }
export function useGetLeaderboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
        }
export type GetLeaderboardQueryHookResult = ReturnType<typeof useGetLeaderboardQuery>;
export type GetLeaderboardLazyQueryHookResult = ReturnType<typeof useGetLeaderboardLazyQuery>;
export type GetLeaderboardSuspenseQueryHookResult = ReturnType<typeof useGetLeaderboardSuspenseQuery>;
export type GetLeaderboardQueryResult = Apollo.QueryResult<GetLeaderboardQuery, GetLeaderboardQueryVariables>;
export const ForgotPasswordDocument = gql`
    query forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions: Apollo.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables> & ({ variables: ForgotPasswordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
      }
export function useForgotPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export function useForgotPasswordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordSuspenseQueryHookResult = ReturnType<typeof useForgotPasswordSuspenseQuery>;
export type ForgotPasswordQueryResult = Apollo.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;
export const GetMilanEventByCityDocument = gql`
    query GetMilanEventByCity($city: String!) {
  getMilanEventByCity(city: $city) {
    id
    name
    description
    date
    price
    city
    venue
    maxParticipants
  }
}
    `;

/**
 * __useGetMilanEventByCityQuery__
 *
 * To run a query within a React component, call `useGetMilanEventByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilanEventByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilanEventByCityQuery({
 *   variables: {
 *      city: // value for 'city'
 *   },
 * });
 */
export function useGetMilanEventByCityQuery(baseOptions: Apollo.QueryHookOptions<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables> & ({ variables: GetMilanEventByCityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables>(GetMilanEventByCityDocument, options);
      }
export function useGetMilanEventByCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables>(GetMilanEventByCityDocument, options);
        }
export function useGetMilanEventByCitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables>(GetMilanEventByCityDocument, options);
        }
export type GetMilanEventByCityQueryHookResult = ReturnType<typeof useGetMilanEventByCityQuery>;
export type GetMilanEventByCityLazyQueryHookResult = ReturnType<typeof useGetMilanEventByCityLazyQuery>;
export type GetMilanEventByCitySuspenseQueryHookResult = ReturnType<typeof useGetMilanEventByCitySuspenseQuery>;
export type GetMilanEventByCityQueryResult = Apollo.QueryResult<GetMilanEventByCityQuery, GetMilanEventByCityQueryVariables>;
export const GetMilanEventDocument = gql`
    query GetMilanEvent {
  getMilanEvent {
    city
    date
    description
    id
    maxParticipants
    name
    price
    venue
  }
}
    `;

/**
 * __useGetMilanEventQuery__
 *
 * To run a query within a React component, call `useGetMilanEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilanEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilanEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMilanEventQuery(baseOptions?: Apollo.QueryHookOptions<GetMilanEventQuery, GetMilanEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMilanEventQuery, GetMilanEventQueryVariables>(GetMilanEventDocument, options);
      }
export function useGetMilanEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMilanEventQuery, GetMilanEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMilanEventQuery, GetMilanEventQueryVariables>(GetMilanEventDocument, options);
        }
export function useGetMilanEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMilanEventQuery, GetMilanEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMilanEventQuery, GetMilanEventQueryVariables>(GetMilanEventDocument, options);
        }
export type GetMilanEventQueryHookResult = ReturnType<typeof useGetMilanEventQuery>;
export type GetMilanEventLazyQueryHookResult = ReturnType<typeof useGetMilanEventLazyQuery>;
export type GetMilanEventSuspenseQueryHookResult = ReturnType<typeof useGetMilanEventSuspenseQuery>;
export type GetMilanEventQueryResult = Apollo.QueryResult<GetMilanEventQuery, GetMilanEventQueryVariables>;
export const GetUserMilanRegistrationsDocument = gql`
    query GetUserMilanRegistrations($userId: String!) {
  getUserMilanRegistrations(userId: $userId) {
    event_id
    id
    participants
    razorpay_order_id
    user_id
    verified
    reg_date
    link
  }
}
    `;

/**
 * __useGetUserMilanRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetUserMilanRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMilanRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMilanRegistrationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserMilanRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables> & ({ variables: GetUserMilanRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables>(GetUserMilanRegistrationsDocument, options);
      }
export function useGetUserMilanRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables>(GetUserMilanRegistrationsDocument, options);
        }
export function useGetUserMilanRegistrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables>(GetUserMilanRegistrationsDocument, options);
        }
export type GetUserMilanRegistrationsQueryHookResult = ReturnType<typeof useGetUserMilanRegistrationsQuery>;
export type GetUserMilanRegistrationsLazyQueryHookResult = ReturnType<typeof useGetUserMilanRegistrationsLazyQuery>;
export type GetUserMilanRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetUserMilanRegistrationsSuspenseQuery>;
export type GetUserMilanRegistrationsQueryResult = Apollo.QueryResult<GetUserMilanRegistrationsQuery, GetUserMilanRegistrationsQueryVariables>;
export const GetMilanEventByIdDocument = gql`
    query GetMilanEventById($getMilanEventByIdId: String!) {
  getMilanEventById(id: $getMilanEventByIdId) {
    city
    date
    description
    id
    maxParticipants
    name
    price
    venue
  }
}
    `;

/**
 * __useGetMilanEventByIdQuery__
 *
 * To run a query within a React component, call `useGetMilanEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilanEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilanEventByIdQuery({
 *   variables: {
 *      getMilanEventByIdId: // value for 'getMilanEventByIdId'
 *   },
 * });
 */
export function useGetMilanEventByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables> & ({ variables: GetMilanEventByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables>(GetMilanEventByIdDocument, options);
      }
export function useGetMilanEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables>(GetMilanEventByIdDocument, options);
        }
export function useGetMilanEventByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables>(GetMilanEventByIdDocument, options);
        }
export type GetMilanEventByIdQueryHookResult = ReturnType<typeof useGetMilanEventByIdQuery>;
export type GetMilanEventByIdLazyQueryHookResult = ReturnType<typeof useGetMilanEventByIdLazyQuery>;
export type GetMilanEventByIdSuspenseQueryHookResult = ReturnType<typeof useGetMilanEventByIdSuspenseQuery>;
export type GetMilanEventByIdQueryResult = Apollo.QueryResult<GetMilanEventByIdQuery, GetMilanEventByIdQueryVariables>;
export const GetMilanRegistrationsByEventIdDocument = gql`
    query GetMilanRegistrationsByEventId($eventId: String!) {
  getMilanRegistrationsByEventId(eventId: $eventId) {
    event {
      city
      date
      description
      id
      maxParticipants
      name
      price
      venue
    }
    registration {
      event_id
      id
      razorpay_order_id
      user_id
      verified
      link
    }
    user {
      user_id
      username
      verified
      year_of_study
      email_primary
      mobile_number_primary
    }
  }
}
    `;

/**
 * __useGetMilanRegistrationsByEventIdQuery__
 *
 * To run a query within a React component, call `useGetMilanRegistrationsByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilanRegistrationsByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilanRegistrationsByEventIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetMilanRegistrationsByEventIdQuery(baseOptions: Apollo.QueryHookOptions<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables> & ({ variables: GetMilanRegistrationsByEventIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables>(GetMilanRegistrationsByEventIdDocument, options);
      }
export function useGetMilanRegistrationsByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables>(GetMilanRegistrationsByEventIdDocument, options);
        }
export function useGetMilanRegistrationsByEventIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables>(GetMilanRegistrationsByEventIdDocument, options);
        }
export type GetMilanRegistrationsByEventIdQueryHookResult = ReturnType<typeof useGetMilanRegistrationsByEventIdQuery>;
export type GetMilanRegistrationsByEventIdLazyQueryHookResult = ReturnType<typeof useGetMilanRegistrationsByEventIdLazyQuery>;
export type GetMilanRegistrationsByEventIdSuspenseQueryHookResult = ReturnType<typeof useGetMilanRegistrationsByEventIdSuspenseQuery>;
export type GetMilanRegistrationsByEventIdQueryResult = Apollo.QueryResult<GetMilanRegistrationsByEventIdQuery, GetMilanRegistrationsByEventIdQueryVariables>;
export const GetMyAccommodationsDocument = gql`
    query GetMyAccommodations {
  getMyAccommodations {
    id
    name
    email
    mobile_no
    num_males
    num_females
    arrival
    departure
    college_name
    refunds {
      amountExtra
      amountRefunded
      num_females
      num_males
    }
  }
}
    `;

/**
 * __useGetMyAccommodationsQuery__
 *
 * To run a query within a React component, call `useGetMyAccommodationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAccommodationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAccommodationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAccommodationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>(GetMyAccommodationsDocument, options);
      }
export function useGetMyAccommodationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>(GetMyAccommodationsDocument, options);
        }
export function useGetMyAccommodationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>(GetMyAccommodationsDocument, options);
        }
export type GetMyAccommodationsQueryHookResult = ReturnType<typeof useGetMyAccommodationsQuery>;
export type GetMyAccommodationsLazyQueryHookResult = ReturnType<typeof useGetMyAccommodationsLazyQuery>;
export type GetMyAccommodationsSuspenseQueryHookResult = ReturnType<typeof useGetMyAccommodationsSuspenseQuery>;
export type GetMyAccommodationsQueryResult = Apollo.QueryResult<GetMyAccommodationsQuery, GetMyAccommodationsQueryVariables>;
export const GetAllAccommodationsDocument = gql`
    query GetAllAccommodations {
  getAllAccommodations {
    id
    name
    email
    num_females
    num_males
    arrival
    departure
    college_name
    aadhaar
    student_id
    amount
    mobile_no
  }
}
    `;

/**
 * __useGetAllAccommodationsQuery__
 *
 * To run a query within a React component, call `useGetAllAccommodationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccommodationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccommodationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccommodationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>(GetAllAccommodationsDocument, options);
      }
export function useGetAllAccommodationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>(GetAllAccommodationsDocument, options);
        }
export function useGetAllAccommodationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>(GetAllAccommodationsDocument, options);
        }
export type GetAllAccommodationsQueryHookResult = ReturnType<typeof useGetAllAccommodationsQuery>;
export type GetAllAccommodationsLazyQueryHookResult = ReturnType<typeof useGetAllAccommodationsLazyQuery>;
export type GetAllAccommodationsSuspenseQueryHookResult = ReturnType<typeof useGetAllAccommodationsSuspenseQuery>;
export type GetAllAccommodationsQueryResult = Apollo.QueryResult<GetAllAccommodationsQuery, GetAllAccommodationsQueryVariables>;
export const GetAllRefundsDocument = gql`
    query GetAllRefunds {
  getAllRefunds {
    id
    amountExtra
    amountRefunded
    num_females
    num_males
    accommodation {
      email
      mobile_no
      arrival
      departure
      name
      amount
    }
  }
}
    `;

/**
 * __useGetAllRefundsQuery__
 *
 * To run a query within a React component, call `useGetAllRefundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRefundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRefundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRefundsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRefundsQuery, GetAllRefundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRefundsQuery, GetAllRefundsQueryVariables>(GetAllRefundsDocument, options);
      }
export function useGetAllRefundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRefundsQuery, GetAllRefundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRefundsQuery, GetAllRefundsQueryVariables>(GetAllRefundsDocument, options);
        }
export function useGetAllRefundsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRefundsQuery, GetAllRefundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRefundsQuery, GetAllRefundsQueryVariables>(GetAllRefundsDocument, options);
        }
export type GetAllRefundsQueryHookResult = ReturnType<typeof useGetAllRefundsQuery>;
export type GetAllRefundsLazyQueryHookResult = ReturnType<typeof useGetAllRefundsLazyQuery>;
export type GetAllRefundsSuspenseQueryHookResult = ReturnType<typeof useGetAllRefundsSuspenseQuery>;
export type GetAllRefundsQueryResult = Apollo.QueryResult<GetAllRefundsQuery, GetAllRefundsQueryVariables>;
export const GetMyRefundsDocument = gql`
    query GetMyRefunds {
  getMyRefunds {
    accommodation {
      email
      mobile_no
      arrival
      departure
    }
    id
    num_males
    num_females
    amountRefunded
    amountExtra
  }
}
    `;

/**
 * __useGetMyRefundsQuery__
 *
 * To run a query within a React component, call `useGetMyRefundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRefundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRefundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRefundsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyRefundsQuery, GetMyRefundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyRefundsQuery, GetMyRefundsQueryVariables>(GetMyRefundsDocument, options);
      }
export function useGetMyRefundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyRefundsQuery, GetMyRefundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyRefundsQuery, GetMyRefundsQueryVariables>(GetMyRefundsDocument, options);
        }
export function useGetMyRefundsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyRefundsQuery, GetMyRefundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyRefundsQuery, GetMyRefundsQueryVariables>(GetMyRefundsDocument, options);
        }
export type GetMyRefundsQueryHookResult = ReturnType<typeof useGetMyRefundsQuery>;
export type GetMyRefundsLazyQueryHookResult = ReturnType<typeof useGetMyRefundsLazyQuery>;
export type GetMyRefundsSuspenseQueryHookResult = ReturnType<typeof useGetMyRefundsSuspenseQuery>;
export type GetMyRefundsQueryResult = Apollo.QueryResult<GetMyRefundsQuery, GetMyRefundsQueryVariables>;