import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useGetMeQuery } from "./generated/graphql";
import { Loader } from "lucide-react";

// Lazy load components
const Ambassador = React.lazy(() => import("./pages/sa/ambassador/ambassador"));
const AuthPage = React.lazy(() => import("./pages/auth/auth"));
const ForgotPassword = React.lazy(() => import("./pages/auth/forgotpassword/forgot"));
const ResetPassword = React.lazy(() => import("./pages/auth/forgotpassword/resetpassword"));
const Dashboard = React.lazy(() => import("./pages/sa/ambassador/Dashboard"));
const Tasks = React.lazy(() => import("./combonents/sa/Tasks/Tasks"));
const CompletedTasks = React.lazy(() => import("./combonents/sa/Tasks/Completed"));
const Leaderboard = React.lazy(() => import("./combonents/sa/leaderboard/Leaderboard"));
const Admin = React.lazy(() => import("./pages/sa/admin/admin"));
const SubmissionsManagement = React.lazy(() => import("./pages/sa/admin/submissions/submissions"));
const TaskManagement = React.lazy(() => import("./pages/sa/admin/tasks/adminTasks"));
const Milan = React.lazy(() => import("./pages/milan/milan"));
const Events = React.lazy(() => import("./pages/milan/events/events"));
const MilanAdmin = React.lazy(() => import("./pages/milan/admin/MilanAdmin"));
const UserDashboard = React.lazy(() => import("./pages/milan/dashboard/userDashboard"));
const Accommodation = React.lazy(() => import("./pages/accommodation/accommodation"));
const Accommodationn = React.lazy(() => import("../src/combonents/dummy_acommodation/Acommodation"));
const MyAccommodation = React.lazy(() => import("./pages/accommodation/myAccommodation"));
const AccommodationAdmin = React.lazy(() => import("./pages/sa/admin/accommodation/accommodation"));
const ProfilePage = React.lazy(() => import("./pages/auth/profileSetup/profileSetup"));

function App() {
  const { data, error, loading } = useGetMeQuery();

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] scale-[5]">
        {" "}
        <Loader />
      </div>}>
          <Routes>
            <Route path="/" element={<Ambassador />} />
            <Route path="/accommodation-govind" element={<Accommodation />} />
            <Route path="/accommodation" element={<Accommodationn />} />
            <Route path="/accommodation/dashboard" element={<MyAccommodation />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:id1/:id2" element={<ResetPassword />} />
            <Route path="/test" element={<ResetPassword />} />
            <Route path="auth" element={<AuthPage />} />
            <Route path="profile-setup" element={<ProfilePage />} />
            <Route path="/milans" element={<Milan />} />
            <Route path="milans/:city" element={<Events />} />
            <Route path="milans/dashboard" element={<UserDashboard />} />
            {data?.getMe.positions[97] === "1" && (
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="tasks" element={<Tasks />} />
                <Route path="completed" element={<CompletedTasks />} />
                <Route path="leaderboard" element={<Leaderboard />} />
              </Route>
            )}
            {data?.getMe.positions[7] === "1" && (
              <>
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/accommodations" element={<AccommodationAdmin />} />
                <Route path="/admin/milans" element={<MilanAdmin />} />
                <Route path="/admin/tasks" element={<TaskManagement />} />
                <Route path="/admin/submissions" element={<SubmissionsManagement />} />
              </>
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
